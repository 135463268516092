<template>
  <div class="device-container">
    <div class="device-heading">
      <div class="device-title">
        <img @click="goBack()" src="/images/back_button.png" alt="Back" class="device-title-button">
        <span>PAUL ONE {{deviceDetails.uid}}</span>
      </div>
      <span class="device-title-date">{{formatDate()}}</span>
    </div>
      <div class="device-datatable" style="overflow-x:auto;">
        <div class="device-table">
          <div class="table-head">
            <div class="table-row">
              <div class="table-column table-head-column-time">
                <div class="column">
                  <div class="column-title">Zeit</div>
                </div>
              </div>
              <div class="table-column" v-for="field in columns" :key="field">
                <div class="column">
                  <div class="column-title">{{ field.text }} (&deg;C)</div>
                </div>
              </div>
            </div>
          </div>
          <spinner v-if="loading" class="spinner"></spinner>
          <div class="table-body">
            <div class="table-row" v-for="(date, index) in deviceDate" :key="index">
                <span class="table-column table-column-time">{{ validTime(date.time)}}</span>
                <span class="table-column" v-for="(sensor, index) in date.sensors" :key="index">
                  <span v-if="sensor.max" class="max">{{sensor.temperature !== null ? validData(sensor.temperature) : sensor.temperature}}</span>
                  <span v-else-if="sensor.min" class="min">{{sensor.temperature !== null ? validData(sensor.temperature) : sensor.temperature}}</span>
                  <span v-else class="middle">{{sensor.temperature !== null ? validData(sensor.temperature) : sensor.temperature}}</span>
                </span>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Spinner from "../../components/Spinner.vue"
import * as _ from 'lodash';
import { mapGetters, mapActions } from "vuex";
import moment from 'moment';

export default {
  name: 'SelectedDateDevice',
  components: {
    Spinner,
  },
  data() {
    return {
      columns: [],
      deviceDate: {},
      payload: {
        id: null,
        date: null,
        search: {
          offset: 0,
        }
      },
      showDetails: true,
      showError: true,
      deviceDetails: null,
      maxValue: null,
      minValue: null,
      newData: [],
      data: [],
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['getSelectedDateData', 'getSensors']),
    tableHead() {
      return this.columns;
    },
  },  
  created() {
    this.deviceDetails = JSON.parse(localStorage.getItem('deviceDetails'));
    this.getSensorsConfig().then(() => {
      this.loading = true;
    this.selectedDate();
    this.renameHeader();
    });
  },
  methods: {
    ...mapActions(['getSelectedDateDevice','getSensorsConfig']),
    validTime(time) {
      // const newTime = time.split(':');
      // const x = new Date();
      // const currentTimeZone = String(x.getTimezoneOffset() / 60);
      // const sign = currentTimeZone.substr(0,1);
      // const timeZone = currentTimeZone.substr(1);
      // if(sign === '-') {
      //   newTime[0] = (+newTime[0]) + (+timeZone)
      // } else if(sign === '+') {
      //   newTime[0] = (+newTime[0]) - (+timeZone)
      // }
      // return newTime.join(':');
      let newTime = time.split('-').join(':');
      return newTime;
    },
    validData(data) {
      let num = +(Math.round(data + "e+1")  + "e-1");
      if(Number.isInteger(num)) {
        return num.toFixed(1).replace(/\./g, ',') ;
      } else {
        return String(num).replace(/\./g, ',');
      }
    },
    renameHeader() {
      for(let i = 0; i < this.getSensors.length; i++) {
        let count = 0
        if(this.getSensors[i].sensors_id.length > 1) {
          count = this.getSensors[i].sensors_id.length;
          for(let j = 1; j <= count; j++) {
            this.columns.push({
              text: `${this.getSensors[i].name} ${j}`,
              sensor: this.getSensors[i].sensors_id[j-1]
            })
          }
        } else {
          this.columns.push({
              text: `${this.getSensors[i].name}`,
              sensor: this.getSensors[i].sensors_id[0]
          })
        }
      }
      for(let i = 1; i <= this.columns.length; i++) {
        this.data.push({
          sensor: i,
          min: null,
          max: null
        })
      }
    },
    selectedDate() {
      this.payload.id = window.location.href.split('/')[5];
      this.payload.date = window.location.href.split('/')[6];
      this.getSelectedDateDevice(this.payload).then(() => {
        this.dataConfiguration();
        this.loading = false;
      });
    },
    sensorConfiguration() {
      for(let i = 0; i < this.getSelectedDateData.measurements.length; i++) {
        const time = this.getSelectedDateData.measurements[i]._id
        const arr = [];
        for(let j = 0; j < this.columns.length; j++) {
          let value = null;
          for(let z = 0; z < this.getSelectedDateData.measurements[i].sensors.length; z++) {
            if(this.getSelectedDateData.measurements[i].sensors[z].sensor_id == this.columns[j].sensor) {
              value = this.getSelectedDateData.measurements[i].sensors[z].temperature;
              break;
            } 
          }
          arr.push({
            temperature: value
          });
        }
        const obj = {};
        obj.time = time;
        obj.sensors = arr;
        this.newData.push(obj)
      }
    },
    searchMinMaxForData() {
      for(let i = 0; i < this.newData.length; i++) {
        for(let j = 0; j < this.newData[i].sensors.length; j++) {
          if(i == 0 && this.newData[i].sensors[j].temperature != '') {
            this.data[j].max = this.newData[i].sensors[j].temperature
            this.data[j].min = this.newData[i].sensors[j].temperature
          }
          if(this.newData[i].sensors[j].temperature == null) {
            continue;
          }
          if(this.data[j].max < this.newData[i].sensors[j].temperature) {
            this.data[j].max = this.newData[i].sensors[j].temperature
          }
          if(this.data[j].min > this.newData[i].sensors[j].temperature) {
            this.data[j].min = this.newData[i].sensors[j].temperature
          }
        }
      }
    },
    addMinMaxFlag() {
      for(let i = 0; i < this.newData.length; i++) {
        for(let j = 0; j < this.newData[i].sensors.length; j++) {
          if(this.data[j].max == this.newData[i].sensors[j].temperature) {
            this.newData[i].sensors[j].max = true
          }
          if(this.data[j].min == this.newData[i].sensors[j].temperature) {
            this.newData[i].sensors[j].min = true
          }
        }
      }
    },
    dataConfiguration() {
      this.sensorConfiguration();
      this.searchMinMaxForData();
      this.addMinMaxFlag();
      this.deviceDate = this.newData;
    },
    formatDate() {
      const url = window.location.href.split('/');
      let date = url[6].split('-').reverse().join('.');
      return date;
    },
    goBack() {
      this.$router.back();
    }
  }
}
</script>

<style lang="scss" scoped>
.spinner {
  left: 50%;
  top: 50%;
}
.device-container {
  font-family: 'Roboto', sans-serif;
  padding-top: 45px;
  margin-left: 45px;
  margin-right: 45px;
  padding-bottom: 50px;

  .device-heading {
    display: flex;
    align-items: center;

    .device-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: auto;
      font-weight: 500;
      font-size: 36px;
      color: #022259;
      text-align: left;
      font-style: normal;

      .device-title-button {
        width: 15px;
        height: 27px;
        margin-right: 20px;
        cursor: pointer;
      }
    }

    .device-title-date {
      margin-left: 50px;
      font-size: 36px;
      color: #022259;
    }
  }

  .device-table {
    width: 100%;

    .table-head, .table-body {
      width: 100%;

      .table-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 50px;

        .column {
          margin-left: 10px;
        }

        .column-title {
          text-align: center;
        }
      }
    }

    .table-head {
      font-weight: 500;
      font-size: 18px;
      color: #022259;
      border: 2px solid #EFF9FF;
      height: 50px;
      position: sticky; 
      z-index: 9999;
      top: 0;
      background: #ffffff;

      .table-row {

        .table-head-column-time {
          position: sticky;
          left: 0;
          background: #ffffff;
        }

        .table-column {
          width: 270px;

          .column {
            padding: 10px;
          }
        }
      }
    }

    .table-body {
      font-size: 18px;
      font-weight: 400;
      color: #022259;
      width: 100%;
      border: 2px solid #EFF9FF;

      .table-row {
        min-height: 60px;
        background: #ffffff;
        &:nth-child(odd) {
          background: #EFF9FF;
        }

        .table-column-time {
          position: sticky;
          z-index: 2;
          left: 0;
          padding-left: 5px;
          &:nth-child(odd) {
          background: inherit;
          }
        }

        .table-column {
          text-align: center;
          line-height: 22px;
          width: 270px;

          .slash {
            margin-left: 5px;
            margin-right: 5px;
          }

          .min {
            color: #1255C8;
          }

          .max {
            color: #A73131;
          }

          .middle {
            color: #022259;
          }
        }

        .table-column-image {
          width: 30px;
          height: 30px;
          margin-left: 20px;
        }
      }

      .table-row:hover {
        background: #e2f5ff;
      }
    }
  }
  
  .device-datatable {
    display: grid;
    height: 66vh;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 35px;
  }
}

.container {
      position: relative;
      height: 45px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 16px;
      margin: auto 0;
      width: 16px;
      height: 16px;
      background: url('/images/calendar.png') no-repeat;
      background-size: cover;
    }
    input {
      width: 100%;
    }
} 

@media screen and (min-width: 1400px) and (max-width: 1920px){
  template {
    width: 100%;

    .device-container {
      width: 100%;
      padding-left: 45px;
    }
  }
}

@media screen and (max-width: 1400px){
  .device-heading {
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 2010px){
  .device-heading {
    width: 1920px;
    margin: 0 auto;
  }

  .device-container {
    width: 1920px !important;
    margin: 0 auto !important;
  }
}
</style>
